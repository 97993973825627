import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import "./Links.css";
import "./Buttons.css";

class Links extends Component {
    render() {
        return (
            <main className="reports">
            <h2>Länkar</h2>
            <p>Här samlas alla länkar till olika projekt som jag utfört i kursen webbprogrammering vid BTH</p>
            <ul className="weekNav">
                <li>
                    <Link to={{ pathname: "https://github.com/bjorn-87/"}} target="_blank">Mina repon på Github</Link>
                </li>
                <li>
                    <Link to={{ pathname: "https://www.student.bth.se/~bjos19/dbwebb-kurser/htmlphp/me/kmom10/nvm/"}} target="_blank">htmlphp projekt - Nättraby vägmuseum</Link>
                </li>
                <li>
                    <Link to={{ pathname: "https://www.student.bth.se/~bjos19/dbwebb-kurser/javascript1/me/kmom10/intelligence/"}} target="_blank">javascript1 - intelligenstest</Link>
                </li>
                <li>
                    <Link to={{ pathname: "https://www.student.bth.se/~bjos19/dbwebb-kurser/ramverk1/me/kmom10/proj/htdocs/"}} target="_blank">PHP Ramverk Projekt</Link>
                </li>
                <li>
                <Link to={{ pathname: "https://www.student.bth.se/~bjos19/dbwebb-kurser/ramverk1/me/redovisa/htdocs/"}} target="_blank">Redovisa sida PHP ramverk1</Link>
                </li>
                <li>
                    <Link to={{ pathname: "https://trading.bjos19.me/"}} target="_blank">JSramverk Projekt</Link>
                </li>
            </ul>
            </main>
        );
    }
};

export default Links;
