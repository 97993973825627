// import React, { Component } from 'react';
// import ReactMarkdown from 'react-markdown/with-html';
// import GetUrl from './GetUrl.js'

// import mdfile from './markdown/me.md';

// class Me extends Component {
//     constructor(props) {
//         super(props)
//
//         this.baseUrl = GetUrl();
//         this.state = {
//             error: null,
//             isLoaded: false,
//             data: []
//         };
//     }
//
//     componentDidMount() {
//         fetch(this.baseUrl)
//         .then((response) => response.json())
//         .then(
//             (res) => {
//                 this.setState({
//                     isLoaded: true,
//                     data: res.data
//                 });
//             },
//             (error) => {
//                 this.setState({
//                     isLoaded: true,
//                     error
//                 });
//             }
//         )
//     }
//     render() {
//         // console.log(this.state.data.msg);
//         return (
//             <main className="info">
//             <ReactMarkdown source={this.state.data.msg} escapeHtml={false} />
//             </main>
//         );
//     }
// }
//
// export default Me;



import React from 'react';
import "./Me.css";


const Me = () => {
    return (
        <main className="info">
            <img className="me" src='me.jpg' alt="Bild på mig" />
            <p>Work in progress...</p>
            <h1>Länkar till mina projekt</h1>
            <p>Mitt Namn är Björn Olsson och på denna sida är tänkt att innehålla mitt cv samt mina projekt från bl.a. min utbildning i webbprogrammering vid BTH.</p>
        </main>
    );
}

export default Me;
