import React from 'react';
import {
    BrowserRouter as Router,
    Link,
    Route
} from 'react-router-dom';

import Me from './Me.js';
import Links from './Links.js';
import Logo from './logo.svg';

import './App.css';

function App() {
    return (
        <Router>
            <div className="App">
                <header className="App-header">
                <div className="NavContainer">
                    <nav className="NavBar">
                        <img className="logo" src={Logo} alt="logo" />
                        <ul>
                            <li>
                                <Link to="/">Hem</Link>
                            </li>
                            <li>
                                <Link to="/links">Länkar</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <Route exact path="/" component={Me} />
                <Route path="/links" component={Links} />
                </header>
                <footer className="pageFooter">
                    <p>&copy; Björn Olsson 2021</p>
                </footer>
            </div>
        </Router>
    );
}

export default App;
